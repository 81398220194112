$rate: 254% 30% 54% 170% 254% 24% 55% 175%;
$height: 2rem 1rem 1rem 1rem 2rem 1rem 1rem 1rem;
$height1: 1rem 0.5rem 0.5rem 0.5rem 1rem 0.5rem 0.5rem 0.5rem;
$opacity: 80% 40% 40% 40% 80% 40% 40% 40%;

@mixin mobile {
  @media screen and (max-width: 540px) {
    @content;
  }
}

@mixin mobil {
  @media screen and (max-width: 1050px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 820px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

.slide1 {
  animation: scroll1 40s linear infinite;
}
.slide2 {
  animation: scroll2 40s linear infinite;
}
.slide3 {
  animation: scroll4 40s linear infinite;
}
.animation-stop {
  animation-play-state: paused;
}
.slide5 {
  animation: scroll4 35s linear infinite;
  -webkit-animation: scroll4 35s linear infinite;
  -moz-animation: scroll4 35s linear infinite;
}
@keyframes scroll1 {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes scroll2 {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes scroll4 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@-webkit-keyframes scroll4 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@-moz-keyframes scroll4 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@for $i from 1 through 4 {
  .Line1 {
    position: relative;
    width: 100%;
    height: 50%;
    .bar#{$i} {
      background: linear-gradient(
        0deg,
        rgba(25, 110, 130, 0.5),
        rgba(25, 110, 130, 0.5)
      );
      border-radius: 4px;
      width: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: nth($height, $i);
      float: left;
      margin-top: 10px+16px * (($i)-1);
      opacity: nth($opacity, $i);
      @include mobile {
        height: nth($height1, $i);
      }
      @-webkit-keyframes animate#{$i} {
        0% {
          width: 0px;
        }
        100% {
          width: ((nth($rate, ($i)) * 100)/254);
        }
      }
      @-moz-keyframes animate#{$i} {
        0% {
          width: 0px;
        }
        100% {
          width: ((nth($rate, ($i)) * 100)/254);
        }
      }
      @keyframes animate#{$i} {
        0% {
          width: 0px;
        }
        100% {
          border: 1px solid #0f465a;
          width: ((nth($rate, ($i)) * 100)/254);
        }
      }
      -webkit-animation: animate#{$i} 0.5s $i * 0.5s forwards;
      -moz-animation: animate#{$i} 0.5s $i * 0.5s forwards;
      animation: animate#{$i} 0.5s $i * 0.5s forwards;
    }
    .span#{$i} {
      position: relative;
      @if $i == 1 {
        right: -3rem;
      } @else {
        right: -2rem;
      }
      opacity: 100%;
      @if $i == 1 {
        font-size: 20px;
      } @else {
        font-size: 14px;
      }
      font-weight: 600;
      opacity: 0;
      @include mobile {
        @if $i == 0 {
          font-size: 12px;
        } @else {
          font-size: 8px;
        }
        right: -1.2rem;
      }
      @keyframes show#{$i} {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      animation: show#{$i} 0.1s (($i)+1) * 0.5s forwards;
    }
  }
}
.Line2 {
  position: relative;
  width: 70%;
  height: 50%;
  .bar5 {
    background: linear-gradient(
      270deg,
      #ffbfa4 -16.83%,
      rgba(255, 191, 164, 0) 145.43%
    );
    border-radius: 4px;
    width: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: nth($height, 5);
    margin-top: 0%;
    opacity: nth($opacity, 5);
    @include mobile {
      height: nth($height1, 5);
    }
    @-webkit-keyframes animate5 {
      0% {
        width: 0px;
      }
      100% {
        width: ((nth($rate, (5)) * 100)/254);
      }
    }
    @-moz-keyframes animate5 {
      0% {
        width: 0px;
      }
      100% {
        width: ((nth($rate, (5)) * 100)/254);
      }
    }
    @keyframes animate5 {
      0% {
        width: 0px;
      }
      100% {
        border: 1px solid #f47c49;
        width: ((nth($rate, (5)) * 100)/254);
      }
    }
    -webkit-animation: animate5 0.5s forwards;
    -moz-animation: animate5 0.5s forwards;
    animation: animate5 0.5s 0.5s forwards;
  }
  .span5 {
    position: relative;
    right: -3rem;
    opacity: 100%;
    font-size: 20px;
    font-weight: 600;
    opacity: 0;

    @include mobile {
      font-size: 12px;
      right: -1rem;
    }
    @keyframes show5 {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    animation: show5 0.1s 1s forwards;
  }

  @for $i from 6 through 8 {
    .bar#{$i} {
      background: linear-gradient(
        270deg,
        #ffbfa4 -16.83%,
        rgba(255, 191, 164, 0) 145.43%
      );
      border-radius: 4px;
      width: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: nth($height, $i);
      margin-top: 10px+16px * (($i)-5);
      opacity: nth($opacity, $i);
      float: left;
      @include mobile {
        height: nth($height1, $i);
      }
      @-webkit-keyframes animate#{$i} {
        0% {
          width: 0px;
        }
        100% {
          width: ((nth($rate, ($i)) * 100)/254);
        }
      }
      @-moz-keyframes animate#{$i} {
        0% {
          width: 0px;
        }
        100% {
          width: ((nth($rate, ($i)) * 100)/254);
        }
      }
      @keyframes animate#{$i} {
        0% {
          width: 0px;
        }
        100% {
          border: 1px solid #f47c49;
          width: ((nth($rate, ($i)) * 100)/254);
        }
      }
      -webkit-animation: animate#{$i} 0.5s (($i)-4) * 0.5s forwards;
      -moz-animation: animate#{$i} 0.5s (($i)-4) * 0.5s forwards;
      animation: animate#{$i} 0.5s (($i)-4) * 0.5s forwards;
    }
    .span#{$i} {
      position: relative;
      right: -2rem;
      opacity: 100%;
      font-size: 14px;
      font-weight: 600;
      opacity: 0;
      @include mobile {
        font-size: 8px;
        right: -1rem;
      }
      @keyframes show#{$i} {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      animation: show#{$i} 0.1s (($i)-3) * 0.5s forwards;
    }
  }
}

.animate1 {
  animation: animat1 0.5s forwards;
}
.animate2 {
  animation: animat2 1s 0.5s forwards;
}
.animate3 {
  animation: animat3 0.5s 0.5s forwards;
}
@keyframes animat1 {
  0% {
    height: 0;
  }
  100% {
    height: 34%;
  }
}
@keyframes animat2 {
  0% {
    height: 0;
  }
  100% {
    height: 66%;
  }
}
@keyframes animat3 {
  0% {
    height: 0;
  }
  100% {
    height: 34%;
  }
}

.menus {
  @include mobil {
    height: 45rem;
    gap: 2px !important;
  }
  @include mobile {
    height: 20rem;
    gap: 2px !important;
  }
}

.ze-card-full-wrap {
  display: flex;
}
.ze-card-invi-wrap {
  display: flex;
  cursor: pointer;
  margin: 10px auto;
  overflow: visible;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  &:hover {
    .ze-img-w {
      transform: translateZ(20px);
      &:after {
        top: 200%;
      }
    }
    .ze-title:after {
      width: 90%;
    }
  }
}
.ze-card {
  max-width: 100% !important;
  padding: 25px;
  height: 250px;
  @include tablet {
    height: 200px;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 25px !important;
  background: #fff;
  border-radius: 3px;
  box-shadow: 1.5569335222244263px 14.012401580810547px 58.38500213623047px 0px
    rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(239, 230, 230, 1);
  position: relative;
  transform-style: preserve-3d;
  perspective: 1000px;
  z-index: 5;
  .ze-img {
    width: 80px;
    height: 70px;
    overflow: visible;
    @include tablet {
      width: 60px;
      height: 60px;
    }
    .image {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      object-position: center;
    }
  }
  .ze-title {
    font-size: 26px;
    line-height: 29px;
    font-weight: 400;
    margin-bottom: 5px;
    position: relative;
    left: 15px;
    top: 8px;
    transform-style: preserve-3d;
    transform: translateZ(25px);
    @include tablet {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 10px;
    }
  }
  .ze-description {
    color: rgba(18, 41, 55, 1);
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    opacity: 0.6;
    position: relative;
    left: 8px;
    top: 5px;
    transform-style: preserve-3d;
    transform: translateZ(5px);
    @include tablet {
      font-size: 15px;
      line-height: 20px;
    }
  }
}
